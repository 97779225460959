// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kadra-js": () => import("./../src/pages/kadra.js" /* webpackChunkName: "component---src-pages-kadra-js" */),
  "component---src-pages-kalendarz-js": () => import("./../src/pages/kalendarz.js" /* webpackChunkName: "component---src-pages-kalendarz-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-obozy-js": () => import("./../src/pages/obozy.js" /* webpackChunkName: "component---src-pages-obozy-js" */),
  "component---src-pages-przedszkola-js": () => import("./../src/pages/przedszkola.js" /* webpackChunkName: "component---src-pages-przedszkola-js" */),
  "component---src-pages-sukcesy-js": () => import("./../src/pages/sukcesy.js" /* webpackChunkName: "component---src-pages-sukcesy-js" */),
  "component---src-pages-treningi-js": () => import("./../src/pages/treningi.js" /* webpackChunkName: "component---src-pages-treningi-js" */)
}

